import { FIELD_ERROR_MESSAGES } from './FieldErrorMessages';

export const isEmailValid = (email: string): string => {
  if (!email || email === '') {
    return FIELD_ERROR_MESSAGES.email.emptyValue;
  }
  // eslint-disable-next-line
 if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    return FIELD_ERROR_MESSAGES.email.invalidValue;
  }
  return '';
};

export const isPhoneNumberValid = (phoneNumber: string): string => {
  if (!phoneNumber || phoneNumber === '') {
    return ''
  }
  if (!/^$|^(?:\(\+?\d+\)|\+?\d+)(?:\s*[-/]*\s*\d+)+(?:)$/.test(phoneNumber)) {
    return FIELD_ERROR_MESSAGES.phoneNumber.invalidValue;
  }
  return '';
};
