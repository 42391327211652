import { Input } from '@eg/elements/Input';
import { FormRow } from '@eg/elements/FormRow';
import { Radio } from '@eg/elements/Radio';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';
import { Select } from '@eg/elements/Select';
import { ControlWithHint } from '@eg/elements/ControlWithHint';
import { FormValues } from '../../../types/FormValues'

import './InsuranceData.scss';
import { isErgo } from '../../../utils';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import React from 'react';

export const INQUIRY_TYPES = {
  INSURANCE: 'INSURANCE',
  CLAIM: 'DAMAGE',
  TECHNICAL_HELP: 'TECHNICAL_HELP',
  LOB_OR_BESCHWERDE: 'LOB_OR_BESCHWERDE',
  BERATUNG: 'BERATUNG',
};

const ERGO_DIRECT_VALUES = [
  { title: 'KFZ', name: 'KFZ' },
  { title: 'Kranken', name: 'KRANKEN' },
  { title: 'Haftpflicht', name: 'HAFTPFLICHT' },
  { title: 'Hausrat', name: 'HAUSRAT' },
  { title: 'Reise', name: 'REISE' },
  { title: 'Internet-Schutzbrief', name: 'SCHUTZBRIEF' },
  { title: 'Maxi-Zins', name: 'MAXIZINS' },
  { title: 'Unfall', name: 'UNFALL' },
  { title: 'Leben', name: 'LEBEN' },
];

const LOB_OR_BESCHWERDE_VALUES = [
  'LEBEN',
  'KRANKEN',
  'SACH',
  'SCHADENFALL',
  'ANGEBOT',
  'SONSTIGES',
];

interface InsuranceDataProps {
  values: FormValues
  handleChange: FormikHandlers['handleChange']
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  handleBlur: FormikHandlers['handleBlur']
  isLeadformular: boolean
}

const InsuranceData: React.FC<InsuranceDataProps> = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  isLeadformular
}) => (
  <div className="InsuranceData form-section">
    <h3>Versicherung</h3>

    <FormRow
      label="inquiryType"
      colspans={{ regular: [1], medium: [1], large: [1, 1] }}
    >
      <ControlWithHint error={touched.inquiryType && errors.inquiryType}>
        <Select
          data-testid="inquiryTypeSelect"
          name="inquiryType"
          onChange={handleChange}
          value={isLeadformular ? INQUIRY_TYPES.BERATUNG : values.inquiryType}
          placeholder="Anliegen *"
          onBlur={handleBlur}
        >
          {isLeadformular ? (
            <option value={INQUIRY_TYPES.BERATUNG}>Beratung</option>
          ) : (
            <optgroup>
              <option disabled value="">
                Bitte wählen
              </option>
              <option value={INQUIRY_TYPES.INSURANCE} data-testid="insuranceOption">Versicherung</option>
              <option value={INQUIRY_TYPES.TECHNICAL_HELP}>
                Technische Hilfe
              </option>
              <option value={INQUIRY_TYPES.LOB_OR_BESCHWERDE}>
                Lob oder Beschwerde
              </option>
            </optgroup>
          )}
        </Select>
      </ControlWithHint>
    </FormRow>

    {!isLeadformular && (
      <React.Fragment>
        {values.inquiryType === INQUIRY_TYPES.INSURANCE && (
          <FormRow
            label="insuranceContractNumber"
            colspans={{ regular: [1], medium: [1, 1], large: [1, 1] }}
          >
            <ControlWithHint
              error={
                touched.insuranceContractNumber
                && errors.insuranceContractNumber
              }
            >
              <Input
                name="insuranceContractNumber"
                placeholder="Versicherungsnummer *"
                value={values.insuranceContractNumber}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </ControlWithHint>
            <ControlWithHint
              error={touched.insuranceProduct && errors.insuranceProduct}
            >
              {!isErgo(values.insuranceContractNumber) && (
                <Select
                  name="insuranceProduct"
                  onChange={handleChange}
                  value={values.insuranceProduct}
                  placeholder="Produkt *"
                  onBlur={handleBlur}
                >
                  <option disabled value="">
                    Bitte auswählen *
                  </option>
                  {ERGO_DIRECT_VALUES.map(ergoDirectValue => (
                    <option
                      key={ergoDirectValue.title}
                      value={ergoDirectValue.name}
                    >
                      {ergoDirectValue.title}
                    </option>
                  ))}
                </Select>
              )}
            </ControlWithHint>
          </FormRow>
        )}

        {values.inquiryType === INQUIRY_TYPES.TECHNICAL_HELP && (
          <React.Fragment>
            <RadioGroupRow
              name="medium"
              label="Medium *"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.medium && errors.medium}
              className="show-descriptor"
            >
              <Radio value="WEBSITE" label="Website" />
              <Radio value="CUSTOMER_AREA" label="Kundenbereich" />
            </RadioGroupRow>

            {values.medium === 'CUSTOMER_AREA' && (
              <FormRow
                label="insuranceContractNumber"
                colspans={{ regular: [1], medium: [1, 1], large: [1, 1] }}
              >
                <ControlWithHint
                  error={
                    touched.insuranceContractNumber
                    && errors.insuranceContractNumber
                  }
                >
                  <Input
                    name="insuranceContractNumber"
                    placeholder="Versicherungsnummer *"
                    value={values.insuranceContractNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ControlWithHint>
              </FormRow>
            )}
          </React.Fragment>
        )}

        {values.inquiryType === INQUIRY_TYPES.LOB_OR_BESCHWERDE && (
          <FormRow
            label="lobOderBeschwerde"
            colspans={{ regular: [1], medium: [1], large: [1, 1] }}
          >
            <ControlWithHint
              error={touched.insuranceProduct && errors.insuranceProduct}
            >
              <Select
                name="insuranceProduct"
                onChange={handleChange}
                value={values.insuranceProduct}
                placeholder="Produkt *"
                onBlur={handleBlur}
              >
                <option disabled value="">
                  Bitte auswählen *
                </option>
                {LOB_OR_BESCHWERDE_VALUES.map(lobValue => (
                  <option key={lobValue} value={lobValue}>
                    {lobValue}
                  </option>
                ))}
              </Select>
            </ControlWithHint>
          </FormRow>
        )}
      </React.Fragment>
    )}
  </div>
);

export default InsuranceData;
