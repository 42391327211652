import {
  checkForSpecialCharactersOrt,
  checkForSpecialCharactersTextInput, checkPlzValid, checkStringWithNumbers,
  checkStringWithTextNumbersForbidden
} from './ValidationHelper';
import {FIELD_ERROR_MESSAGES} from  './FieldErrorMessages';

export const isStreetValid = (street: string): string => {
  if (!street || street === '') {
    return FIELD_ERROR_MESSAGES.strasse.emptyValue;
  }
  if (checkForSpecialCharactersTextInput(street)) {
    return FIELD_ERROR_MESSAGES.strasse.specialCharacters;
  }
  if (street.length < 2) {
    return FIELD_ERROR_MESSAGES.strasse.invalidLength;
  }
  return '';
};

export const isHnrValid = (hausnummer: string): string => {
  if (!hausnummer || hausnummer === '') {
    return FIELD_ERROR_MESSAGES.hausnummer.emptyValue;
  }
  if (hausnummer.length > 10) {
    return FIELD_ERROR_MESSAGES.hausnummer.invalidLength
  }
  if (!/^\d[\s/\-\w]*$/.test(hausnummer)) {
    return FIELD_ERROR_MESSAGES.hausnummer.invalidValue;
  }
  if (hausnummer && !checkStringWithNumbers(hausnummer.substr(0, 1))) {
    return FIELD_ERROR_MESSAGES.hausnummer.invalidFirstNumber;
  }
  return '';
};

export const isPostcodeValid = (postcode: string): string => {
  if (!postcode || postcode === '') {
    return FIELD_ERROR_MESSAGES.plz.emptyValue;
  }
  if (postcode.length !== 5) {
    return FIELD_ERROR_MESSAGES.plz.invalidLength;
  }
  if (!checkPlzValid(postcode)) {
    return FIELD_ERROR_MESSAGES.plz.invalidValue;
  }
  return '';
};

export const isCityValid = (ort: string): string => {
  if (!ort || ort === '') {
    return FIELD_ERROR_MESSAGES.ort.emptyValue;
  }
  if (ort.length < 2) {
    return FIELD_ERROR_MESSAGES.ort.invalidLength;
  }
  if (checkStringWithNumbers(ort)) {
    return FIELD_ERROR_MESSAGES.ort.invalidValue;
  }
  if (checkForSpecialCharactersOrt(ort)) {
    return FIELD_ERROR_MESSAGES.ort.invalidValue;
  }
  if (!checkStringWithTextNumbersForbidden(ort.substr(0, 2))) {
    return FIELD_ERROR_MESSAGES.ort.invalidFirstCharacters;
  }
  return '';
};
