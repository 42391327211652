import React from 'react';
import { FormRow } from '@eg/elements/FormRow';
import { Textarea } from '@eg/elements/Textarea';
import { Checkbox } from '@eg/elements/Checkbox';
import { ControlWithHint } from '@eg/elements/ControlWithHint';

import './Message.scss';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import { FormValues } from '../../../types/FormValues';

interface MessageProps {
  values: FormValues
  handleChange: FormikHandlers['handleChange']
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  messagePlaceholder: string
}

const Message: React.FC<MessageProps> = ({
  values,
  handleChange,
  errors,
  touched,
  messagePlaceholder
}) => (
  <div className="Message form-section">
    <h3>Nachricht</h3>
    <FormRow
      label="message"
      colspans={{
        regular: [1],
        medium: [1],
        large: [1],
      }}
    >
      <ControlWithHint error={touched.message && errors.message}>
        <Textarea
          minRows={25}
          maxRows={40}
          name="message"
          defaultValue={values.message}
          placeholder={messagePlaceholder}
          onChange={handleChange}
        />
      </ControlWithHint>
    </FormRow>
    <FormRow
      label="emailReply"
      colspans={{
        regular: [1],
        medium: [1],
        large: [1],
      }}
    >
      <ControlWithHint
        key={1}
        description="Ich wünsche die Antwort auf meine Anfrage per E-Mail. Ich weiß, dass E-Mail zwar die schnellste, aber nicht die sicherste Möglichkeit der Kommunikation ist. ERGO Direkt AG hat die möglichen technischen Sicherungsmaßnahmen im Internetauftritt umgesetzt, kann jedoch keine Gewähr dafür übernehmen, dass sich Dritte unbefugt Einblick in die Antwortdaten verschaffen. Für besonders vertrauliche Informationen, wie zum Beispiel Gesundheitsdaten, oder wenn die Schriftform erforderlich ist, wird die ERGO Direkt AG daher je nach Einzelfall zu meinem Schutz auf anderem Weg mit mir in Verbindung treten."
      >
        <Checkbox
          label="Antwort per E-Mail"
          name="consentReplyViaEmail"
          value={values.consentReplyViaEmail.toString()}
          onChange={handleChange}
        />
      </ControlWithHint>
    </FormRow>
    <FormRow label="">
      <span
        className="ee_control-with-hint__hint"
      >
        Füllen Sie bitte die mit &ldquo;*&rdquo; gekennzeichneten Felder aus.
      </span>
    </FormRow>
  </div>
);

export default Message;
