import React from 'react';
import MessageBox from '@eg/elements/MessageBox';

interface ErrorBoxProps {
  htmlError: string
}

const ErrorBox: React.FunctionComponent<ErrorBoxProps> = ({htmlError}) => {
  return (
    <>
      <MessageBox type='error'>
        <b>Fehler mit Ihrer Anfrage</b>
        <br/>
        <p>
          Leider konnten wir Ihre Anfrage nicht verarbeiten.<br/>
          {htmlError.startsWith('4') ? 'Bitte überprüfen Sie Ihre Eingaben' : 'Bitte versuchen Sie es später erneut'} oder
          rufen Sie uns an.<br/>
          Wir entschuldigen uns für die Unannehmlichkeiten.
        </p>
      </MessageBox>
    </>);
}

export default ErrorBox;
