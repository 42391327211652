import React from 'react';
import { Input } from '@eg/elements/Input';
import { Radio } from '@eg/elements/Radio';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';
import { FormRow } from '@eg/elements/FormRow';
import { ControlWithHint } from '@eg/elements/ControlWithHint';

import './PersonalData.scss';
import FormSection from '@eg/elements/FormSection';
import DateInputRow from '@eg/elements/DateInputRow';
import { FormValues } from '../../../types/FormValues';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import { DateInputValue } from '@eg/elements/DateInput';
import { convertDateInputValueToDDMMYYYYString, convertDDMMYYYYStringToDateInputValue } from '../../../utils';

interface PersonalDataProps {
  values: FormValues
  isLeadformular: boolean
  handleChange: FormikHandlers['handleChange']
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  handleBlur: FormikHandlers['handleBlur']
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
}

const PersonalData: React.FC<PersonalDataProps> = ({
   values,
   isLeadformular,
   handleChange,
   errors,
   touched,
   handleBlur,
   setFieldValue,
   setFieldTouched
 }) => (
  <FormSection className="PersonalData">
    <h3>Persönliche Daten</h3>
    <RadioGroupRow
      name="title"
      label="Anrede *"
      onChange={handleChange}
      onBlur={handleBlur}
      className="show-descriptor"
      error={touched.title && errors.title}
    >
      <Radio value="frau" label="Frau" data-testid="radio-frau"/>
      <Radio value="herr" label="Herr"/>
    </RadioGroupRow>
    <FormRow
      label="name"
      colspans={{regular: [1], medium: [1, 1], large: [1, 1]}}
    >
      <ControlWithHint error={touched.firstName && errors.firstName}>
        <Input
          name="firstName"
          placeholder="Vorname *"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={50}
        />
      </ControlWithHint>

      <ControlWithHint error={touched.lastName && errors.lastName}>
        <Input
          name="lastName"
          placeholder="Nachname *"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={50}
        />
      </ControlWithHint>
    </FormRow>
    <FormRow
      label="street"
      colspans={{regular: [1], medium: [3, 1], large: [3, 1]}}
    >
      <ControlWithHint error={touched.street && errors.street}>
        <Input
          name="street"
          error={false}
          placeholder="Strasse *"
          value={values.street}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={50}
        />
      </ControlWithHint>
      <ControlWithHint error={touched.number && errors.number}>
        <Input
          name="number"
          placeholder="Hausnummer *"
          value={values.number}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={10}
        />
      </ControlWithHint>
    </FormRow>
    <FormRow
      label="city"
      colspans={{regular: [1], medium: [1, 2], large: [1, 3]}}
    >
      <ControlWithHint error={touched.postcode && errors.postcode}>
        <Input
          name="postcode"
          placeholder="Postleitzahl *"
          maxLength={5}
          value={values.postcode}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </ControlWithHint>
      <ControlWithHint error={touched.city && errors.city}>
        <Input
          name="city"
          placeholder="Ort *"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={30}
        />
      </ControlWithHint>
    </FormRow>
    {isLeadformular &&
    <ControlWithHint error={touched.birthday && errors.birthday} className="show-descriptor">
      <DateInputRow
        value={convertDDMMYYYYStringToDateInputValue(values.birthday)}
        label="Geburtsdatum"
        onChange={(value: DateInputValue) => {
          setFieldValue('birthday', convertDateInputValueToDDMMYYYYString(value));
          setFieldTouched('birthday', true);
        }}
        onBlur={handleBlur}
      />
    </ControlWithHint>
    }
  </FormSection>
);

export default PersonalData;
