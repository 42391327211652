import DateInput, { DateInputValue } from '@eg/elements/DateInput';

const ERGO_VALIDATION_VALUES = ['SV', 'LV', 'KV', 'DA', 'KR', 'AS', 'HA', 'UV', 'T', 'FL', 'LF', 'R', 'GV', 'LG'];

const POSITION_AKTIONSNUMMER = 0;
const POSITION_VERMITTLERNUMMER = 1;

const splitAktionCookie = (aktionNr: string): string[] => {
  aktionNr = aktionNr.replace('%2C', ','); // eslint-disable-line
  return aktionNr.split(',');
};

export const getAktionNr = (aktionNr: string) => {
  if (aktionNr) {
    const split = splitAktionCookie(aktionNr);
    return split[POSITION_AKTIONSNUMMER];
  }
  return null;
};

export const getVermittlerNrAusAktionsNrCookie = (aktionNr: string) => {
  if (aktionNr) {
    const split = splitAktionCookie(aktionNr);
    if (split.length === 2 && split[POSITION_VERMITTLERNUMMER] !== '') {
      return split[POSITION_VERMITTLERNUMMER];
    }
  }
  return null;
};

export const isErgo = (insuranceContractNumber: string): boolean => ERGO_VALIDATION_VALUES.some(
  value => value.toLowerCase()
    === insuranceContractNumber.toLowerCase().substr(0, 2),
);

export const convertDateInputValueToDDMMYYYYString = (dateInputValue: DateInputValue): string => {
  const day = dateInputValue.day ? dateInputValue.day : 'DD';
  const month = dateInputValue.month ? dateInputValue.month : 'MM';
  const year = dateInputValue.year ? dateInputValue.year : 'YYYY';

  return [day, month, year].join('-'); 
};

export const convertDateToDateInputValue = (date: Date): DateInputValue => {
  if (!DateInput.defaultProps || !DateInput.defaultProps.value) {
    return {};
  }

  const dateInput = DateInput.defaultProps.value;

  dateInput.year = String(date.getFullYear());
  dateInput.month = String(date.getMonth() + 1);
  dateInput.day = String(date.getDate());
  return dateInput;
};

export const convertDateInputValueToDate = (dateInputValue: DateInputValue): Date => {
  return new Date(fillWithZero(String(dateInputValue.year), 4) + '-' +
    fillWithZero(String(dateInputValue.month), 2) + '-' +
    fillWithZero(String(dateInputValue.day), 2));
};

const fillWithZero = (stringToFill: string, length: number): string => {
  return '0'.repeat(length - `${stringToFill}`.length) + `${stringToFill}`;
};

export const convertDDMMYYYYStringToDateInputValue = (date: string): DateInputValue => {
  let dateInputValue: DateInputValue = {};
  const dateParts = date.split('-');
  if (dateParts[0] !== 'DD') {
    dateInputValue.day = dateParts[0];
  }
  if (dateParts[1] !== 'MM') {
    dateInputValue.month = dateParts[1];
  }
  if (dateParts[2] !== 'YYYY') {
    dateInputValue.year = dateParts[2];
  }
  return dateInputValue;
};
