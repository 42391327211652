import * as React from 'react';
import { FormSection } from '@eg/elements/FormSection';
import { MessageBox } from '@eg/elements/MessageBox';
import { Recap } from '@eg/elements/Recap';
import { RouteComponentProps, withRouter } from 'react-router';
import FeedbackComponent from './FeedbackComponent';
import { FormValues } from '../../types/FormValues';
import { Component } from 'react';

interface FeedbackProps extends RouteComponentProps{
  aktionsnummer: string
  vermittlernummer: string
}

const NACHRICHT_ERFOLGREICH = `Ihre Nachricht wurde erfolgreich an uns gesendet.
Wir werden diese umgehend bearbeiten.

Ihre ERGO Kundenberatung`;

class Feedback extends Component<FeedbackProps> {
  constructor(props: FeedbackProps) {
    super(props);

    if (!this.props.location.state) {
      this.props.history.replace({
        pathname: '/'
      });
      return;
    }
  }

  render() {
    if (!this.props.location.state) {
      this.props.history.replace({
        pathname: '/'
      });
      return null;
    }

    const formularState: FormValues = this.props.location.state;
    const {title, firstName, lastName, street, number, postcode} = formularState;
    const {
      city,
      insuranceContractNumber,
      email,
      phoneNumber,
      message,
      consentReplyViaEmail
    } = formularState;

    return (
      <div style={{margin: '20px'}}>
        <MessageBox>
          {Feedback.renderAnrede(title, firstName, lastName)}
          <br/>
          <p style={{whiteSpace: 'pre-line'}}>{NACHRICHT_ERFOLGREICH}</p>
        </MessageBox>
        <FormSection>
          <h3>Ihre Angaben:</h3>
          <br/>
          <div>
            <FeedbackComponent
              title="Name:"
              value={Feedback.concatWithSeperator(firstName, ' ', lastName)}
            />
            <FeedbackComponent
              title="Straße / Hausnummer:"
              value={Feedback.concatWithSeperator(street, ' ', number)}
            />
            <FeedbackComponent
              title="PLZ / Ort:"
              value={Feedback.concatWithSeperator(postcode, ' ', city)}
            />
            <FeedbackComponent
              title="Telefonnummer:"
              value={phoneNumber}
            />
            <FeedbackComponent title="E-Mail Adresse:" value={email}/>
            <FeedbackComponent title="Versicherungsscheinnummer:" value={insuranceContractNumber}/>
          </div>
          {Feedback.renderMitteilungRecap(message)}
        </FormSection>
        <br/>
        {consentReplyViaEmail ? <p>Sie wünschen die Antwort auf Ihre Anfrage per E-Mail.</p> : null}
      </div>
    );
  }

  private static concatWithSeperator(part1: string, seperator: string, part2: string) {
    if (part1 && part2) {
      return part1 + seperator + part2;
    }
    return '';
  }

  private static renderAnrede(title: string, vorname: string, nachname: string) {
    return (
      <h3 data-compponet-id="anredeFeedback">
        {title === 'herr' ? 'Sehr geehrter ' : 'Sehr geehrte '}
        {title[0].toUpperCase() + title.slice(1)} {vorname} {nachname},
      </h3>
    );
  }

  private static renderMitteilungRecap(mitteilung: string | undefined) {
    return (
      mitteilung &&
      mitteilung !== '' && (
        <Recap title="Ihre Mitteilung:">
          <p data-compponet-id="mitteilungFeedback" style={{whiteSpace: 'pre-line'}}>
            {mitteilung}
          </p>
        </Recap>
      )
    );
  }
}

export default withRouter(Feedback);
