import { FIELD_ERROR_MESSAGES } from './FieldErrorMessages';
import { isAfter, isBefore, isValid, subYears } from 'date-fns';
import {
  checkForInvalidCharactersInName,
  checkStringWithTextNumbersForbidden
} from './ValidationHelper';
import { convertDateInputValueToDate, convertDDMMYYYYStringToDateInputValue } from '../../../utils';

export const isTitleValid = (title: string) => {
  if (title === '') {
    return FIELD_ERROR_MESSAGES.anrede.emptyValue;
  }
  if (title !== 'frau' && title !== 'herr') {
    return FIELD_ERROR_MESSAGES.anrede.invalidValue;
  }
  return '';
};

export const isVornameValid = (name: string) => {
  if (
    checkForInvalidCharactersInName(name)
  ) {
    return FIELD_ERROR_MESSAGES.vorname.invalidValue;
  }

  if (!name || name === '') {
    return FIELD_ERROR_MESSAGES.vorname.emptyValue;
  }
  if (name && name.length < 2) {
    return FIELD_ERROR_MESSAGES.vorname.invalidLength;
  }
  if (!checkStringWithTextNumbersForbidden(name.substr(0, 2))) {
    return FIELD_ERROR_MESSAGES.vorname.invalidFirstCharacters;
  }
  return '';
};

export const isNachnameValid = (name: string) => {
  if (
    checkForInvalidCharactersInName(name)
  ) {
    return FIELD_ERROR_MESSAGES.nachname.invalidValue;
  }

  if (!name || name === '') {
    return FIELD_ERROR_MESSAGES.nachname.emptyValue;
  }
  if (name && name.length < 2) {
    return FIELD_ERROR_MESSAGES.nachname.invalidLength;
  }
  if (!checkStringWithTextNumbersForbidden(name.substr(0, 2))) {
    return FIELD_ERROR_MESSAGES.nachname.invalidFirstCharacters;
  }
  return '';
};

export const isBirthdayValid = (birthdayString: string): string => {
  const birthday = convertDDMMYYYYStringToDateInputValue(birthdayString);
  if (!birthday || !birthday.day || !birthday.month || !birthday.year) {
    return FIELD_ERROR_MESSAGES.geburtstag.emptyValue;
  }
  if (Number(birthday.day) > 31 || Number(birthday.month) > 12 ||
    Number(birthday.day) < 1 || Number(birthday.month) < 1) {
    return FIELD_ERROR_MESSAGES.geburtstag.invalidDate;
  }
  const birthdayDate = convertDateInputValueToDate(birthday);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isAfterDate = subYears(today, 100);

  if (isValid(birthdayDate) && isBefore(birthdayDate, subYears(today, 18)) && isAfter(birthdayDate, isAfterDate)) {
    return '';
  }
  return FIELD_ERROR_MESSAGES.geburtstag.invalidValue;
};

export const isInsuranceContractNumberValid = (insuranceContractNumber: string): string => {
  if (!insuranceContractNumber || insuranceContractNumber === '') {
    return FIELD_ERROR_MESSAGES.vsnr.emptyValue;
  }
  if (insuranceContractNumber.length > 20) {
    return FIELD_ERROR_MESSAGES.vsnr.invalidLength;
  }
  if (!/^[a-zA-Z0-9]*$/.test(insuranceContractNumber)) {
    return FIELD_ERROR_MESSAGES.vsnr.invalidValue;
  }
  return '';
};
