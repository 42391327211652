import React from 'react';
import { Input } from '@eg/elements/Input';
import { FormRow } from '@eg/elements/FormRow';
import { ControlWithHint } from '@eg/elements/ControlWithHint';

import './ContactData.scss';
import FormSection from '@eg/elements/FormSection';
import { FormValues } from '../../../types/FormValues';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';

interface ContactDataProps {
    values: FormValues
    handleChange: FormikHandlers['handleChange']
    errors: FormikErrors<FormValues>
    touched: FormikTouched<FormValues>
    handleBlur: FormikHandlers['handleBlur']
}

const ContactData: React.FC<ContactDataProps> = ({
  values, handleChange, errors, touched, handleBlur,
}) => (
  <FormSection className="ContactData">
    <h3>Kontaktdaten</h3>
    <FormRow
      label="phoneNumber"
      colspans={{ regular: [1], medium: [1], large: [1] }}
    >
      <ControlWithHint error={touched.phoneNumber && errors.phoneNumber}>
        <Input
          name="phoneNumber"
          placeholder="Rufnummer"
          value={values.phoneNumber}
          maxLength={30}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </ControlWithHint>
    </FormRow>
    <FormRow label="email" colspans={{ regular: [1], medium: [1], large: [1] }}>
      <ControlWithHint error={touched.email && errors.email}>
        <Input
          type="email"
          name="email"
          placeholder="E-Mail *"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={60}
        />
      </ControlWithHint>
    </FormRow>
  </FormSection>
);

export default ContactData;
