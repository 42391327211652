import React, { Component } from 'react';
import { Formik } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as cookies from 'js-cookie';
import { Button } from '@eg/elements/Button';
import { FormRow } from '@eg/elements/FormRow';

import './ContactForm.scss';

import InsuranceData, { INQUIRY_TYPES } from './InsuranceData';
import PersonalData from './PersonalData';
import ContactData from './ContactData';
import Message from './Message';

import { validation } from './validation/validation';
import {
  getAktionNr,
  getVermittlerNrAusAktionsNrCookie,
  isErgo
} from '../../utils';
import { postData } from './api';
import { FormValues } from '../../types/FormValues';
import { CompletedData } from '../../types/CompletedData';
import ErrorBox from './ErrorBox/ErrorBox';

const initialFormValues: FormValues = {
  inquiryType: '',
  insuranceContractNumber: '',
  insuranceProduct: '',
  subject: 'NONE',
  medium: 'NONE',
  title: '',
  firstName: '',
  lastName: '',
  street: '',
  number: '',
  postcode: '',
  city: '',
  country: 'germany',
  phoneNumber: '',
  email: '',
  message: '',
  consentReplyViaEmail: false,
  birthday: 'DD-MM-YYYY'
};

const cookieValues = cookies.get();
const staticValues = {
  aktionsnummer: getAktionNr(cookieValues.aktionNr),
  vermittlernummer: getVermittlerNrAusAktionsNrCookie(cookieValues.aktionNr),
};

interface ContactFormProps extends RouteComponentProps {
  handleIsCompleted: (isCompleted: CompletedData) => void,
  handleResetCompleted: () => void,
  isLeadformular: boolean,
  messagePlaceholder: string
}

class ContactForm extends Component<ContactFormProps> {
  state = {
    isSubmitting: false,
    htmlError: undefined
  };

  onFormSubmit = (values: FormValues) => {
    let data = {
      ...values,
      email: values.email.trim(),
      ...staticValues,
      insuranceContractNumber:
        values.insuranceContractNumber === INQUIRY_TYPES.TECHNICAL_HELP ? '' : values.insuranceContractNumber,
    };

    if (this.props.isLeadformular && values.birthday) {
      data = {
        ...data,
        inquiryType: INQUIRY_TYPES.BERATUNG,
        message: `${data.message} ${document.location.href}`
      };
    }

    this.setState({isSubmitting: true});

    postData(data)
      .then(() => {
        this.setState({isSubmitting: false});
        this.props.history.push({
          pathname: '/feedback',
          state: data
        });
      })
      .catch((error) => {
        this.setState({isSubmitting: false, htmlError: error.response ? error.response.statusCode : 'unknown'});
        console.log(error);
      })
      .then(() => {
          window.scrollTo(0, 0)
      });
  };

  // eslint-disable-next-line no-shadow
  shouldDisplayForm = (
    validation: (values: FormValues, isLeadformular: boolean) => {
      isCompleted: CompletedData
    },
    values: FormValues
  ) => validation(values, this.props.isLeadformular).isCompleted.insuranceData || this.props.isLeadformular;

  render() {
    return (
      <div>
        {
          this.state.htmlError !== undefined &&
          <ErrorBox htmlError={this.state.htmlError!}/>
        }
        <Formik
          initialValues={initialFormValues}
          onSubmit={this.onFormSubmit}
          validate={(values) => {
            const {isCompleted} = validation(values, this.props.isLeadformular);
            this.props.handleIsCompleted(isCompleted);
            return validation(values, this.props.isLeadformular).errors;
          }}
        >
          {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              resetForm,
              setFieldValue,
              setFieldTouched
            }) => (
            <React.Fragment>
              <InsuranceData
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                isLeadformular={this.props.isLeadformular}
              />
              {isErgo(values.insuranceContractNumber) ? (
                <a
                  data-testid="ergo-contact-link"
                  href="https://www.ergo.de/de/Resources/Forms/Kontakt/Kontakt"
                  className="ee_button ee_button--regular ee_button--secondary ee_button--normal"
                >
                  <div className="ee_button_content">
                    Kontaktformular öffnen
                  </div>
                </a>
              ) : (
                this.shouldDisplayForm(validation, values) && (
                  <React.Fragment>
                    <PersonalData
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      isLeadformular={this.props.isLeadformular}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <ContactData
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                    />
                    <Message
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      messagePlaceholder={values.inquiryType === INQUIRY_TYPES.LOB_OR_BESCHWERDE ? 'Bitte geben Sie' +
                        ' Ihre Nachricht und Ihre Versicherungsscheinnummer ein... *' : this.props.messagePlaceholder}
                    />
                    <FormRow label="submit">
                      <div className="form-buttons">
                        <Button
                          variant={this.state.isSubmitting ? "disabled" : "secondary"}
                          onClick={() => {
                            resetForm(initialFormValues);
                            this.props.handleResetCompleted();
                          }}
                        >
                          Zurücksetzen
                        </Button>
                        <Button
                          data-testid="submit-button"
                          type="submit"
                          variant="secondary"
                          onClick={() => handleSubmit()}
                          loading={this.state.isSubmitting}
                        >
                          Abschicken
                        </Button>
                      </div>
                    </FormRow>
                  </React.Fragment>
                )
              )}
            </React.Fragment>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(ContactForm);
