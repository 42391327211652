import axios from 'axios/index';
import { FormValues } from '../../types/FormValues';
import config from '../../config/configFactory';

//TODO outsource data mutation for birthday (single responsibility)
export const postData = (data: FormValues) => axios.post(config.apiUrl, {
  ...data,
  birthday: data.birthday.match("\\d{2}-\\d{2}-\\d{4}") ? data.birthday : undefined
});

