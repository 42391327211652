export const checkForSpecialCharactersTextInput = (str: string): boolean => {
  return /[@~`°§\\!#$%^&*+=[\]';,/(){}|":<>?_]/g.test(str);
};

export const checkForSpecialCharactersOrt = (str: string): boolean => {
  return /[@~`°§\\!#$%^&*+=[\]';,{}|":<>?_]/g.test(str);
};

export const checkForSpecialCharactersNumberInput = (str: string): boolean => {
  return /[@~`°§\\!#$%^&*+=[\]';,/(){}|":<>?_]/g.test(str);
};

export const checkStringWithNumbers = (str: string): boolean => {
  return /[0-9]/g.test(str);
};

export const checkStringWithTextNumbersForbidden = (str: string): boolean => {
  return !checkForAllSpecialCharactersIncludingNumbers(str);
};

export const checkStringWithTextNumbersAllowed = (str: string): boolean => {
  return !checkForAllSpecialCharactersExcludingNumbers(str);
};

export const checkPlzValid = (str: string): boolean => {
  return /[0-9]{5}/g.test(str);
};

export const checkNumbersOnly = (str: string): boolean => {
  return /^[\d]*$/g.test(str);
};

export const checkForInvalidCharactersInName = (str: string) => {
  return /[@~`°§\\!#$%^&*+=[\];,./(){}|":<>?_0-9]/g.test(str);
};

export const checkForAllSpecialCharactersIncludingNumbers = (str: string): boolean => {
  return /[@~`°§\\!#$%^&*+=[\]';,./(){}|":<>?_\-\s0-9]/g.test(str);
};

export const checkForAllSpecialCharactersExcludingNumbers = (str: string): boolean => {
  return /[@~`°§\\!#$%^&*+=[\]';,./(){}|":<>?_\-\s]/g.test(str);
};
