import { INQUIRY_TYPES } from '../InsuranceData';
import { isErgo } from '../../../utils';
import { FormValues } from '../../../types/FormValues';
import { FormikErrors } from 'formik';
import { isHnrValid, isCityValid, isPostcodeValid, isStreetValid } from './AdresseValidation';
import {
  isTitleValid,
  isVornameValid,
  isBirthdayValid,
  isInsuranceContractNumberValid,
  isNachnameValid
} from './PersonValidation';
import { FIELD_ERROR_MESSAGES } from './FieldErrorMessages';
import { isEmailValid, isPhoneNumberValid } from './ContactDataValidation';
import { isMessageValid } from './MessageValidation';
import { CompletedData } from '../../../types/CompletedData';

export const validation = (values: FormValues, isLeadformular: boolean) => {
  const errors: FormikErrors<FormValues> = {};

  const isCompleted: CompletedData = {
    insuranceData: false,
    personalData: false,
    contactData: false,
    message: false,
  };

  if (
    !errors.inquiryType
    && !errors.insuranceContractNumber
    && !errors.insuranceProduct
    && !errors.subject
    && !errors.medium
  ) {
    isCompleted.insuranceData = true;
  }

  // insurance data
  if (!values.inquiryType && !isLeadformular) {
    errors.inquiryType = FIELD_ERROR_MESSAGES.thema.emptyValue;
    isCompleted.insuranceData = false;
  }

  const insuranceContractNumberValidation = isInsuranceContractNumberValid(values.insuranceContractNumber);
  if (
    (values.inquiryType === INQUIRY_TYPES.INSURANCE
      || values.inquiryType === INQUIRY_TYPES.CLAIM)
    && insuranceContractNumberValidation !== ''
  ) {
    errors.insuranceContractNumber = insuranceContractNumberValidation;
    isCompleted.insuranceData = false;
  }

  if (
    (values.inquiryType === INQUIRY_TYPES.INSURANCE
      || values.inquiryType === INQUIRY_TYPES.LOB_OR_BESCHWERDE)
    && !values.insuranceProduct
    && !isErgo(values.insuranceContractNumber)
  ) {
    errors.insuranceProduct = FIELD_ERROR_MESSAGES.produkt.emptyValue;
    isCompleted.insuranceData = false;
  }

  if (values.inquiryType === INQUIRY_TYPES.TECHNICAL_HELP && ((!values.medium || values.medium === 'NONE') || (values.medium === 'CUSTOMER_AREA' && !values.insuranceContractNumber))) {
    errors.medium = FIELD_ERROR_MESSAGES.medium.emptyValue;
    isCompleted.insuranceData = false;
  }

  if (
    !errors.title
    && !errors.firstName
    && !errors.lastName
    && !errors.street
    && !errors.number
    && !errors.postcode
    && !errors.city
    && !errors.country
    && !errors.birthday
  ) {
    isCompleted.personalData = true;
  }

  // personal data
  const titleValidation = isTitleValid(values.title);
  if (titleValidation !== '') {
    errors.title = titleValidation;
    isCompleted.personalData = false;
  }

  const firstNameValidation = isVornameValid(values.firstName);
  if (firstNameValidation !== '') {
    errors.firstName = firstNameValidation;
    isCompleted.personalData = false;
  }

  const lastNameValidation = isNachnameValid(values.lastName);
  if (lastNameValidation !== '') {
    errors.lastName = lastNameValidation;
    isCompleted.personalData = false;
  }

  const streetValidation = isStreetValid(values.street);
  if (streetValidation !== '') {
    errors.street = streetValidation;
    isCompleted.personalData = false;
  }

  const hnrValidation = isHnrValid(values.number);
  if (hnrValidation !== '') {
    errors.number = hnrValidation;
    isCompleted.personalData = false;
  }

  const plzValidation = isPostcodeValid(values.postcode);
  if (plzValidation !== '') {
    errors.postcode = plzValidation;
    isCompleted.personalData = false;
  }

  const cityValidation = isCityValid(values.city);
  if (cityValidation !== '') {
    errors.city = cityValidation;
    isCompleted.personalData = false;
  }

  if (!values.country) {
    errors.country = FIELD_ERROR_MESSAGES.land.emptyValue;
    isCompleted.personalData = false;
  }

  const birthdayValidation = isBirthdayValid(values.birthday);
  if (isLeadformular && birthdayValidation !== '') {
    errors.birthday = birthdayValidation;
    isCompleted.personalData = false;
  }

  if (
    !errors.email
    && !errors.phoneNumber
  ) {
    isCompleted.contactData = true;
  }

  const phoneNumberValidation = isPhoneNumberValid(values.phoneNumber);
  if (phoneNumberValidation !== '') {
    errors.phoneNumber = phoneNumberValidation;
    isCompleted.contactData = false;
  }

  const emailValidation = isEmailValid(values.email);
  if (emailValidation !== '') {
    errors.email = emailValidation;
    isCompleted.contactData = false;
  }

  if (
    !errors.message
  ) {
    isCompleted.message = true;
  }

  const messageValidation = isMessageValid(values.message);
  if (messageValidation !== '') {
    errors.message = messageValidation;
    isCompleted.message = false;
  }

  return { errors, isCompleted };
};
