const keineSonderzeichenFehlermeldung = 'Bitte geben Sie keine Sonderzeichen an.';
const keineSonderzeichenUndZahlenFehlermeldung =
  'Bitte geben Sie keine Sonderzeichen oder Zahlen an.';
const keineSonderzeichenUndBuchstabenFehlermeldung =
  'Bitte geben Sie keine Sonderzeichen oder Buchstaben an.';
const ersteZweiZeichenKeineBuchstaben =
  'Die ersten beiden Zeichen müssen Buchstaben sein.';

export const FIELD_ERROR_MESSAGES = {
  betreff: {
    emptyValue: 'Bitte wählen Sie einen Betreff aus.'
  },
  anrede: {
    emptyValue: 'Bitte wählen Sie Ihre Anrede aus.',
    invalidValue: 'Ungültige Anrede.'
  },
  vorname: {
    emptyValue: 'Bitte geben Sie Ihren Vornamen an.',
    invalidLength: 'Ihr Vorname muss mindestens 2 Zeichen enthalten.',
    specialCharacters: keineSonderzeichenFehlermeldung,
    invalidValue: keineSonderzeichenUndZahlenFehlermeldung,
    invalidFirstCharacters: ersteZweiZeichenKeineBuchstaben
  },
  nachname: {
    emptyValue: 'Bitte geben Sie Ihren Nachnamen an.',
    invalidLength: 'Ihr Nachname muss mindestens 2 Zeichen enthalten.',
    specialCharacters: keineSonderzeichenFehlermeldung,
    invalidValue: keineSonderzeichenUndZahlenFehlermeldung,
    invalidFirstCharacters: ersteZweiZeichenKeineBuchstaben
  },
  strasse: {
    emptyValue: 'Bitte geben Sie Ihren Straßennamen an.',
    specialCharacters: keineSonderzeichenFehlermeldung,
    invalidLength: 'Ihr Straßenname muss mindestens 2 Zeichen enthalten.',
    invalidFirstCharacters: 'Das erste Zeichen muss ein Buchstabe oder eine Zahl sein'
  },
  hausnummer: {
    emptyValue: 'Bitte geben Sie Ihre Hausnummer an.',
    invalidValue: 'Bitte überprüfen Sie ihre Hausnummer.',
    invalidLength: 'Ihre Hausnummer darf maximal 10 Zeichen lang sein',
    invalidFirstNumber: 'Ihre Hausnummer muss mit einer Zahl beginnen.'
  },
  plz: {
    emptyValue: 'Bitte geben Sie Ihre Postleitzahl an.',
    invalidValue: keineSonderzeichenUndBuchstabenFehlermeldung,
    invalidLength: 'Bitte geben Sie Ihre 5-stellige Postleitzahl an.'
  },
  ort: {
    emptyValue: 'Bitte geben Sie Ihren Wohnort an.',
    invalidLength: 'Ihr Wohnort muss mindestens 2 Zeichen enthalten.',
    invalidValue: keineSonderzeichenUndZahlenFehlermeldung,
    specialCharacters: keineSonderzeichenFehlermeldung,
    invalidFirstCharacters: ersteZweiZeichenKeineBuchstaben
  },
  vsnr: {
    emptyValue: 'Bitte geben Sie Ihre Versicherungsscheinnummer an.',
    invalidLength: 'Ihre Versicherungsscheinnummer darf maximal 20 Zeichen lang sein.',
    invalidValue: 'Bitte korrigieren Sie Ihre Versicherungsscheinnummer.'
  },
  vorwahl: {
    emptyValue: 'Bitte geben Sie Ihre Vorwahl an.',
    invalidValue: keineSonderzeichenUndBuchstabenFehlermeldung,
    firstNumberNotNull: 'Ihre Vorwahl muss mit einer "0" beginnen',
    invalidLength: 'Ihre Vorwahl muss mindestens 3 Zeichen enthalten.'
  },
  phoneNumber: {
    emptyValue: 'Bitte geben Sie Ihre Rufnummer an.',
    invalidValue: 'Bitte überprüfen Sie Ihre Rufnummer.'
  },
  email: {
    emptyValue: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
    invalidValue: 'Bitte überprüfen Sie Ihre E-Mail-Adresse.'
  },
  thema: {
    emptyValue: 'Bitte wählen Sie ein Thema aus.'
  },
  nachricht: {
    emptyValue: 'Bitte geben Sie eine Nachricht ein.',
    invalidValue: 'Die Nachricht enthält unzulässige Zeichen.',
    invalidLengthShort: 'Bitte geben Sie mindestens 10 Zeichen ein',
    invalidLengthLong: 'Bitte geben Sie maximal 10000 Zeichen ein',
  },
  geburtstag: {
    emptyValue: 'Bitte geben Sie Ihr Geburtsdatum ein.',
    invalidValue: 'Sie müssen älter als 18 und jünger als 100 Jahre alt sein um ein Versicherungsangebot anzufordern.',
    invalidDate: 'Das eingegebene Datum is ungültig.'
  },
  produkt: {
    emptyValue: 'Bitte wählen Sie ein Produkt aus.'
  },
  medium: {
    emptyValue: 'Bitte wählen Sie ein Medium.'
  },
  land: {
    emptyValue: 'Bitte wählen Sie ein Land.'
  }
};
