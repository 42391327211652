import React, { Component } from 'react';

import Sidebar from './Sidebar';
import ContactForm from './ContactForm';

import './App.scss';
import { CompletedData } from '../types/CompletedData';
import { aemConfig } from '../config/aemConfig';

class App extends Component {
  initialState = {
    isCompleted: {
      insuranceData: false,
      personalData: false,
      contactData: false,
      message: false,
    },
  };

  state = this.initialState;

  componentDidMount() {
    const spinner = document.getElementById('ed_tarifspinner');

    if (spinner && spinner.parentNode) {
      spinner.parentNode.removeChild(spinner);
    }
  }

  handleIsCompleted = (isCompleted: CompletedData) => {
    this.setState({
      isCompleted,
    });
  };

  handleResetCompleted = () => {
    this.setState(this.initialState);
  };

  render() {
    return (
      <div className="App esc_container esc_container--l esc_box">
        <div className="esc_grid">
          <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-12 esc_col-l-3">
              <Sidebar isCompleted={this.state.isCompleted} />
            </div>
            <div className="esc_col esc_col-12 esc_col-l-9">
              <ContactForm
                handleIsCompleted={this.handleIsCompleted}
                handleResetCompleted={this.handleResetCompleted}
                isLeadformular={aemConfig.isLeadformular}
                messagePlaceholder={aemConfig.messagePlaceholder}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
