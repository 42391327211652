import { AemParamsRaw } from '../types/EDWindow';

const APP_ID = 'kontaktformular';

const defaultConfig: AemParamsRaw = {
  isLeadformular: 'false',
  messagePlaceholder: 'Bitte geben Sie Ihre Nachricht ein... *',
  envs: [
    {
      name: 'url',
      url: '',
    },
  ],
};

export const aemConfig = {
  get aemParams(): AemParamsRaw {
    const fullAemAppId = Object.keys(window.params ?? {}).find((id) =>
      id.includes(APP_ID)
    );

    if (fullAemAppId) {
      const extractedAemParams = window.params?.[fullAemAppId];

      return {
        isLeadformular: extractedAemParams?.isLeadformular ?? defaultConfig.isLeadformular,
        messagePlaceholder: extractedAemParams?.messagePlaceholder ?? defaultConfig.messagePlaceholder,
        envs: extractedAemParams?.envs ?? defaultConfig.envs,
      };
    }

    return defaultConfig;
  },
  get backendDomain() {
    return aemConfig.aemParams.envs[0].url;
  },
  get isLeadformular() {
    return aemConfig.aemParams.isLeadformular === 'true';
  },
  get messagePlaceholder() {
    return aemConfig.aemParams.messagePlaceholder;
  }
};
