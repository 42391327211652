import * as React from 'react';

interface FeedbackComponentProps {
  title: string;
  value: string;
}

class FeedbackComponent extends React.Component<FeedbackComponentProps> {
  render() {
    const { title, value } = this.props;

    return value !== '' ? (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <b style={{ flex: '1 0 auto', minWidth: '250px', margin: 0 }}>{title}</b>
        <p
          data-compponet-id={title + 'Feedback'}
          style={{ flex: '1 0 auto', minWidth: '250px', margin: 0 }}
        >
          {value}
        </p>
        <div style={{ minHeight: 45 }} />
      </div>
    ) : null;
  }
}

export default FeedbackComponent;
