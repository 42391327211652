import React from 'react';
import { Link } from 'react-scroll/modules';
import { CheckIcon } from '@eg/elements/Icons/CheckIcon';
import { CompletedData } from '../../types/CompletedData';
import './Sidebar.scss';

interface SidebarProps {
    isCompleted: CompletedData
}

const Sidebar: React.FC<SidebarProps> = ({ isCompleted }) => (
  <div className="Sidebar">
    <div className="Sidebar-item">
      <Link
        activeClass="active"
        to="InsuranceData"
        spy
        smooth
        offset={0}
        duration={500}
      >
        <span>Versicherung</span>
        <CheckIcon
          className={`check-icon ${
            isCompleted.insuranceData ? 'is-completed' : ''
          }`}
        />
      </Link>
    </div>
    <div className="Sidebar-item">
      <Link
        activeClass="active"
        to="PersonalData"
        spy
        smooth
        offset={0}
        duration={500}
      >
        <span>Persönliche Daten</span>
        <CheckIcon
          className={`check-icon ${
            isCompleted.personalData ? 'is-completed' : ''
          }`}
        />
      </Link>
    </div>
    <div className="Sidebar-item">
      <Link
        activeClass="active"
        to="ContactData"
        spy
        smooth
        offset={0}
        duration={500}
      >
        <span>Kontakt</span>
        <CheckIcon
          className={`check-icon ${
            isCompleted.contactData ? 'is-completed' : ''
          }`}
        />
      </Link>
    </div>
    <div className="Sidebar-item">
      <Link
        activeClass="active"
        to="Message"
        spy
        smooth
        offset={0}
        duration={500}
      >
        <span>Nachricht</span>
        <CheckIcon
          className={`check-icon ${isCompleted.message ? 'is-completed' : ''}`}
        />
      </Link>
    </div>
  </div>
);

export default Sidebar;
