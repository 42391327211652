import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Provider } from '@eg/elements/Provider';

import App from './App';

import '@eg/elements/styles/ergo-one/styles.css';
import '@eg/elements/styles/ergo-one/core.css';
import './index.scss';
import Feedback from './App/Feedback/Feedback';

ReactDOM.render(
  <HashRouter>
    <Provider theme="ergo-one">
      <Switch>
        <Route path="/" exact component={App} />
        <Route path="/feedback" exact component={Feedback} />
      </Switch>
    </Provider>
  </HashRouter>,
  document.getElementById('kontaktformular-root'),
);
