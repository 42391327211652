import {FIELD_ERROR_MESSAGES} from './FieldErrorMessages';

export const isMessageValid = (message: string): string => {
  if (!message || message === '') {
    return FIELD_ERROR_MESSAGES.nachricht.emptyValue;
  }
  if (/[\\&[\]{}]/g.test(message)) {
    return FIELD_ERROR_MESSAGES.nachricht.invalidValue;
  }
  if (message.length < 10) {
    return FIELD_ERROR_MESSAGES.nachricht.invalidLengthShort;
  }
  if (message.length > 10000) {
    return FIELD_ERROR_MESSAGES.nachricht.invalidLengthLong;
  }
  return '';
};
